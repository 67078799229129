


































































































import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { log } from 'util';
import * as rules from 'monkey-validates-form';
@Component
export default class formdescarga extends Vue {
    @Prop() public godown!: boolean;
    public data: any = {
        form: 'descarga',
        nombres: '',
        correo: '',
        telefono: '',
        empresa: '',
        industria: '',
        area: '',
        cargo: '',
    };
    public spinner: boolean = false;
    public respuesta: string = '';
    public valid: boolean = false;
    public rules = rules;
    items = ['Retail', 'Banca o Seguros', 'Farmacéutica', 'Medios', 'Tecnología', 'Consultoría', 'Jurídico', 'Textil', 'Alimenticia', 'Automovilística'];
    itemsU = ['Comercial', 'Otro', 'Financiero', 'Producto', 'Marketing', 'Tecnología', 'Operaciones', 'Innovación'];
    public send() {
        this.respuesta = '';
        if (this.valid === true) {
            this.spinner = true;
            const formdata = new FormData();
            const config = {
                headers: { 'Content-Type': 'multipart/form-data' },
            };
            for (const key in this.data) {
                formdata.append(key, this.data[key]);
            }
            console.log(formdata);
            
            axios
                .post('/assets/php/send.php', formdata, config)
                .then((response) => {
                    this.respuesta = response.data.mensaje;
                    this.data.nombres = ' ';
                    this.data.empresa = ' ';
                    this.data.telefono = ' ';
                    this.data.correo = ' ';
                    this.data.industria = ' ';
                    this.data.area = ' ';
                    this.data.cargo = ' ';
                })
                .catch((error) => {
                    this.respuesta = 'Error al enviar el mensaje';
                })
                .finally(() => {      
                    this.spinner = false;
                    setTimeout(() => {
                      let enlace = document.createElement('a');
                      enlace.href = 'https://stupendo.com/docs/stupendo-ebook-plataforma-documentos-electronicos.pdf';
                      enlace.target = '_blank';
                      enlace.click();
                      this.godown = false;
                    }, 3000);
                });
        } else {
            this.respuesta =
                'Para enviar el formulario llena todos los campos.';
        }
    }
}
