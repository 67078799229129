




















import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { log } from 'util';
import * as rules from 'monkey-validates-form';

@Component
export default class suscripcion extends Vue {
    public valid: boolean = false;
    public respuesta: string = '';
    public rules = rules;
    public correo: string = '';
    public save() {
        if (this.valid === true) {
            const formdata = new FormData();
            const config = {
                headers: { 'Content-Type': 'multipart/form-data' },
            };
            formdata.append('email', this.correo);
            formdata.append('form', 'subscripcion');
            axios
                .post('/assets/php/send.php', formdata, config)
                .then((response) => {
                    this.respuesta = response.data.mensaje;
                    this.correo = '';
                })
                .catch((error) => {
                    this.respuesta = 'Lo sentimos, hubo un error al procesar la suscripción';
                });
        } else {
            this.respuesta = 'Para suscribirte ingresa un correo valido.';
        }
    }
}
