























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { log } from 'util';

@Component
export default class casos extends Vue {
  @Prop() public caso!: any[];
  @Prop() public transicion!: boolean;

}
