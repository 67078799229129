




























































































import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { log } from 'util';
import * as rules from 'monkey-validates-form';
@Component
export default class Form extends Vue {
    data: any = {
        form: 'trabaja',
    };

    public clase: string = 'success';
    public colorRespuesta: string = '#00b588';
    public nombreArchivo: string = 'Adjunta tu CV';
    public respuestaServer: string = '';
    public spinner: boolean = false;
    public mostrarAlert: boolean = false;
    public adjunto: any = undefined;
    rules = rules;
    valid = false;
    items = ['Back-End Developer', 'FrontEnd Developer'];

    selectFile() {
        (this.$refs.archivo as HTMLElement).click();
    }

    onFilePicked() {
        this.adjunto = (this.$refs.archivo as any).files[0];
        try {
            this.nombreArchivo = this.adjunto.name;
        } catch (e) {
            this.nombreArchivo = 'Adjunta tu CV';
        }
    }

    sendData() {
        if (this.valid && this.adjunto) {
            this.spinner = true;
            const formdata = new FormData();
            const config = {
                headers: { 'Content-Type': 'multipart/form-data' },
            };
            for (const key in this.data) {
                formdata.append(key, this.data[key]);
            }
            formdata.append('adjunto', this.adjunto);
            console.log(formdata.get('adjunto'));
            axios
                .post('/assets/php/send.php', formdata, config)
                .then((response) => {
                    this.respuestaServer = response.data.mensaje;
                    this.colorRespuesta = '#00b588';
                    this.clase = 'success';
                    this.mostrarAlert = true;
                    this.adjunto = undefined;
                })
                .catch((error) => {
                    this.respuestaServer = 'Error al enviar el mensaje';
                    this.colorRespuesta = 'red';
                    this.clase = 'error';
                    this.mostrarAlert = true;
                })
                .finally(() => {
                    this.spinner = false;
                });
        } else {
            alert('Rellene todos los campos');
        }
    }
}
