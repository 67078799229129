










































































































import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { log } from 'util';
import * as rules from 'monkey-validates-form';
@Component
export default class formcontacto extends Vue {
    @Prop() public godown!: boolean;
    public data: any = {
        form: 'contacto',
        nombres: '',
        empresa: '',
        ruc: '',
        telefono: '',
        correo: '',
        empleados: '',
        documentos: '',
        consulta: '',
    };
    public spinner: boolean = false;
    public respuesta: string = '';
    public valid: boolean = false;
    public rules = rules;
    items = ['Menos de 20', 'Menos de 50', 'Más de 50'];
    public send() {
        this.respuesta = '';
        if (this.valid === true) {
            this.spinner = true;
            const formdata = new FormData();
            const config = {
                headers: { 'Content-Type': 'multipart/form-data' },
            };
            for (const key in this.data) {
                formdata.append(key, this.data[key]);
            }

            axios
                .post('/assets/php/send.php', formdata, config)
                .then((response) => {
                    this.respuesta = response.data.mensaje;
                    this.data.nombres = ' ';
                    this.data.empresa = ' ';
                    this.data.ruc = ' ';
                    this.data.telefono = ' ';
                    this.data.correo = ' ';
                    this.data.empleados = ' ';
                    this.data.documentos = ' ';
                    this.data.consulta = ' ';
                })
                .catch((error) => {
                    this.respuesta = 'Error al enviar el mensaje';
                })
                .finally(() => {
                    this.spinner = false;
                });
        } else {
            this.respuesta =
                'Para enviar el formulario llena todos los campos.';
        }
    }
}
