











import { Component, Prop, Vue } from 'vue-property-decorator';
import { log } from 'util';

@Component
export default class banner extends Vue {
  @Prop() public banner!: any[];
  @Prop() public cambio!: boolean;
}
