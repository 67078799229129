import Vue from 'vue';
import Vuetify, {
    VApp, // required
    VBtn,
    VIcon,
    VWindow,
    VWindowItem,
    VDialog,
    VForm,
    VCarousel,
    VCarouselItem,
} from 'vuetify/lib';
import { Ripple, Scroll, Resize } from 'vuetify/lib/directives';
import 'vuetify/src/stylus/app.styl';

Vue.use(Vuetify, {
    components: {
        VApp,
        VWindow,
        VWindowItem,
        VBtn,
        VDialog,
        VForm,
        VIcon,
        VCarousel,
        VCarouselItem,
    },
    directives: {
        Ripple,
        Scroll,
        Resize,
    },
    theme: {
        primary: '#ff0000',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
    },
    iconfont: 'mdi',
});
