import '@/components';
import '@/config/plugins/vuetify';
import '@/config/sass/main.scss';
import './app.scss';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

document.addEventListener('DOMContentLoaded', (event) => {
    const load: any = document.getElementById('load');
    setTimeout(() => {
        load.classList.add('remove_load');
    }, 2000);
});