
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { log } from 'util';

@Component
export default class serviciosmovil extends Vue {
  @Prop() public servicio!: any[];
}
