



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { log } from 'util';

@Component
export default class servicios extends Vue {
  @Prop() public servicio!: any[];
  @Prop() public cambiar!: boolean;

}
