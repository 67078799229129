import { Component, Vue } from 'vue-property-decorator';
import store from '@/store/index';

@Component({})
class FooterSection extends Vue {
    public godown: boolean = false;
    public todown: boolean = false;
    public toDown() {
        this.godown = !this.godown;
    }
    public politicas() {
        this.todown = !this.todown;
    }
}
export default new FooterSection({
    el: '#footer',
    store,
    delimiters: ['[{', '}]'],
});
