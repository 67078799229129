import '@/commons';
import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/store/index';

@Component({})
class HeadSection extends Vue {
    public deskMenu: boolean = false;
    public scroll: boolean = false;
    public mensaje: string = 'Menú';
    toggleMenu() {
        this.deskMenu = !this.deskMenu;
        if (this.deskMenu === true) {
            this.mensaje = 'Cerrar';
        } else {
            this.mensaje = 'Menú';
        }
    }

    public goToContactos() {
        this.deskMenu = !this.deskMenu;
        const alto = document.getElementById('footer')!;
        const element = alto.offsetTop;
        if (/Edge/.test(navigator.userAgent)) {
            window.scrollTo(0, element);
            this.mensaje = 'Menú';
        } else {
            window.scrollTo({
                top: element,
                left: 0,
                behavior: 'smooth',
            });
            this.mensaje = 'Menú';
        }
    }

    mounted() {
        document.addEventListener('scroll', () => {
            if (window.scrollY > 200) {
                this.scroll = true;
            } else {
                this.scroll = false;
            }
        });
    }

}

export default new HeadSection({
    el: '#head',
    store,
    delimiters: ['[{', '}]'],
});
